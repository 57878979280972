<template>
  <v-button
    :href="targetLink"
    color="white"
    class="text-xs flex items-center gap-x-2"
    target="_blank"
  >
    <img
      src="/img/logo.svg"
      alt="NoteForms"
      class="w-4 h-4"
    >
    <span>
      {{ props.label }}
    </span>
    <Icon
      name="heroicons:arrow-up-right-20-solid"
      class="w-4 h-4"
    />
  </v-button>
</template>

<script setup>
const props = defineProps({
  source: {
    type: String,
    default: 'form'
  },
  label: {
    type: String,
    default: 'Create forms with NoteForms'
  }
})
const targetLink = computed(() => {
  return 'https://noteforms.com?utm_source=' + props.source + '&utm_content=powered_by_btn'
})
</script>
